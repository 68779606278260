@import "_custom-properties.css";
@import "_custom-mixins.css";

.breaking-news-box {
	width: 100%;
	margin: 0 auto 10px;
	display: flex;
	flex-wrap: wrap;

	& .breaking-news-left {
		width: 30%;
		background-color: #C42B2B;
		text-align: center;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 15px 10px;

		& .breaking-news-left-h2 {
			font-family: 'Oswald';
			font-size: 28px;
			font-weight: bold;
			text-transform: uppercase;
			color: #FFFFFF;
			margin: 0;
		}
	}

	& .breaking-news-right {
		width: 70%;
		background-color: #262626;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 15px 10px;

		& .breaking-news-right-h2 {
			font-family: 'Lato';
			font-size: 28px;
			color: #FFFFFF;
			margin: 0;
			font-weight: 300;
			line-height: 1.2;

			& a,
			& a:link,
			& a:visited {
				color: #fff;
				margin-bottom: 0;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.breaking-news-box.widget {
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	& .breaking-news-left {
		width: 100%;
		padding: 15px 10px;
	}

	& .breaking-news-right {
		width: 100%;
		padding: 15px 10px;
		text-align: center;
	}
}

/* Styles for the breaking news banner at smaller screen sizes */
@media only screen and (max-width: 700px) {

	.breaking-news-box {

		& .breaking-news-left {
			width: 100%;
		}

		& .breaking-news-right {
			width: 100%;
			padding-bottom: 10px;
			text-align: center;
		}
	}
}

.breaking-news-box-shortcode {

	& .breaking-news-left {
		width: 100%;
		padding: 15px 10px;
	}

	& .breaking-news-right {
		width: 100%;
		padding: 15px 10px;
		text-align: center;
	}
}
